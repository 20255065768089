/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-25 21:58:17
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-28 18:20:48
 */
import { TaggingItem } from "../interface/common";
import {
  ProductItem,
  ProductItemImage,
  ProductItemOfficialPrices,
} from "../interface/order";
import { NumberOrString } from "../type/common";
import { CurrencyType } from "../type/order";
import { computed } from "vue";
import store from "@/store";

export const getOrderItemAmountTotal = (
  confirmedQty: number,
  unitPrice: number,
  discountPrice: number,
  discountType: number,
  taxCount: number,
  isTaxCount = true
): number => {
  const qty = confirmedQty || 0,
    price = Number(unitPrice),
    prices = price * qty,
    discount = Number(discountPrice),
    discount_type = discountType,
    tax = taxCount;
  if (isTaxCount) {
    return getOrderItemTaxTotal(price, qty, discount, discount_type, tax);
  } else {
    return getOrderItemTotal(price, qty, discount, discount_type, tax);
  }
};

const getOrderItemTotal = (
  price: number,
  qty: number,
  discount: number,
  discount_type: number,
  tax: number
) => {
  if (discount_type === CurrencyType.Amount) {
    const amounts = (price - discount) * qty;
    return amounts > 0 ? amounts : 0;
  } else {
    const amounts = price * ((100 - discount) / 100) * qty;
    return amounts > 0 ? amounts : 0;
  }
};

export const getDiscountPercent = (discount: NumberOrString, isFlag = true) => {
  let percent = Number(discount);
  if (isFlag) {
    if (percent < 0) {
      if (percent < -100) {
        percent = 0;
      } else {
        percent = 100 + percent;
      }
    } else if (percent > 0) {
      percent = 100 + percent;
    }
  }
  return percent;
};

const getOrderItemTaxTotal = (
  price: number,
  qty: number,
  discount: number,
  discount_type: number,
  tax: number
) => {
  if (discount_type === CurrencyType.Amount) {
    const amounts = ((price - discount) / (tax / 100 + 1)) * qty;
    return amounts > 0 ? amounts : 0;
  } else {
    const amounts =
      ((price * ((100 - discount) / 100)) / (tax / 100 + 1)) * qty;
    return amounts > 0 ? amounts : 0;
  }
};

export const getOrderGrossPrice = (price: number, tax: NumberOrString) => {
  const tax_rate = getOrderTax(tax);
  const amounts = Number(price) * (1 + tax_rate / 100);
  return Number(amounts.toFixed(2));
};

export const getOrderTax = (tax) => {
  const tax_rate: number = tax ? Number(tax) : 0;
  return tax_rate;
};

export const getOrderItemVatTotal = (
  confirmedQty: number,
  unitPrice: number,
  discountPrice: number,
  discountType: number,
  taxCount: number,
  isTaxCount = true
) => {
  const qty = confirmedQty || 0,
    price = Number(unitPrice),
    prices = price * qty,
    discount = Number(discountPrice),
    discount_type = discountType,
    tax = taxCount;
  if (isTaxCount) {
    return (
      getOrderItemTaxTotal(price, qty, discount, discount_type, tax) *
      (tax / 100)
    );
  } else {
    return (
      getOrderItemTotal(price, qty, discount, discount_type, tax) * (tax / 100)
    );
  }
};

export const getCurrencyTypeOptions = (): TaggingItem[] => {
  return [
    {
      label: "%",
      value: CurrencyType.Percent,
    },
    {
      label: "€",
      value: CurrencyType.Amount,
    },
  ];
};

/**
 * @description: 获取对应的产品信息
 * @return ProductItem | null
 */
export const getOrderProductIdData = (
  id: NumberOrString,
  Products: ProductItem[]
): ProductItem | null => {
  const arr: ProductItem[] = Products.filter((item: ProductItem) => {
    return item.id === id;
  });
  if (arr.length == 0) {
    return null;
  } else {
    return arr[0];
  }
};

/**
 * @description: 获取产品信息为DE的价格信息
 * @return ProductItemOfficialPrices[]
 */
export const getOrderOfficialPrices = (
  officialPrices: ProductItemOfficialPrices[] | undefined
): ProductItemOfficialPrices[] => {
  let arr: ProductItemOfficialPrices[] = [];
  if (officialPrices) {
    arr = officialPrices.filter((item: ProductItemOfficialPrices) => {
      return item.country_iso_2 === "DE";
    });
  }
  return arr;
};

/**
 * @description: 获取预览图片
 * @return string[]
 */
export const previewOrderImages = (
  previewImages: ProductItemImage[] | undefined
): string[] => {
  // eslint-disable-next-line prefer-const
  let arr: string[] = [];
  if (previewImages) {
    previewImages.forEach((item: ProductItemImage) => {
      arr.push(item.origin_url);
    });
  }
  return arr;
};

/**
 * @description: 获取预览图片
 * @return string[]
 */
export const commonGalleryImages = (
  previewImages: string[]
): ProductItemImage[] => {
  // eslint-disable-next-line prefer-const
  let arr: ProductItemImage[] = [];
  if (previewImages) {
    previewImages.forEach((item) => {
      arr.push({
        id: 0,
        origin_url: item,
      });
    });
  }
  return arr;
};

/**
 * @description: 判断是否是审计DS角色
 * @return Boolean
 */
export const isAuditDsRole = computed(() => {
  const dsRoldId = process.env.VUE_APP_AUDIT_DS_ROLE_ID;
  return store.getters.currentUser.role_ids.includes(Number(dsRoldId));
});

/**
 * @description: 判断是否是审计SS角色
 * @return Boolean
 */
export const isAuditSsRole = computed(() => {
  const ssRoldId = process.env.VUE_APP_AUDIT_SS_ROLE_ID;
  return store.getters.currentUser.role_ids.includes(Number(ssRoldId));
});

/**
 * @description: 判断是否是审计视图
 * @return Boolean
 */
export const isAuditView = computed(() => {
  const dsRoldId = process.env.VUE_APP_AUDIT_DS_ROLE_ID;
  const ssRoldId = process.env.VUE_APP_AUDIT_SS_ROLE_ID;
  return (
    store.getters.currentUser.role_ids.includes(Number(dsRoldId)) ||
    store.getters.currentUser.role_ids.includes(Number(ssRoldId))
  );
});
