import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["colspan"]
const _hoisted_2 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_info = _resolveComponent("product-info")!
  const _component_sku_info = _resolveComponent("sku-info")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("td", {
      colspan: _ctx.productCol,
      rowspan: "1",
      class: "text-start"
    }, [
      (_openBlock(), _createBlock(_component_product_info, {
        key: _ctx.key,
        gallery: _ctx.gallery,
        name: _ctx.name,
        name_en: _ctx.name_en,
        source_link: _ctx.source_link,
        brand_id: _ctx.brand_id
      }, null, 8, ["gallery", "name", "name_en", "source_link", "brand_id"]))
    ], 8, _hoisted_1),
    _createElementVNode("td", {
      colspan: _ctx.skuCol,
      rowspan: "1",
      class: "text-start"
    }, [
      (_openBlock(), _createBlock(_component_sku_info, {
        key: _ctx.key,
        brand_article_no: _ctx.brand_article_no,
        merchant_article_no: _ctx.merchant_article_no,
        color: _ctx.color,
        rangeSize: _ctx.rangeSize,
        size: _ctx.size,
        capacity: _ctx.capacity,
        product_identifier_id: _ctx.product_identifier_id,
        eanShow: _ctx.eanShow,
        ean: _ctx.ean,
        countryShow: _ctx.countryShow,
        country: _ctx.country,
        onRangeSizeModal: _ctx.rangeSizeModal
      }, null, 8, ["brand_article_no", "merchant_article_no", "color", "rangeSize", "size", "capacity", "product_identifier_id", "eanShow", "ean", "countryShow", "country", "onRangeSizeModal"]))
    ], 8, _hoisted_2)
  ], 64))
}