
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import {
  productItemData,
  ProductItemImage,
} from "@/core/directive/interface/order";
import JwtService from "@/core/services/JwtService";
import ProductInfo from "../layout/ProductInfo.vue";
import SkuInfo from "../layout/SkuInfo.vue";

export default defineComponent({
  emits: ["range-size-modal"],
  components: { ProductInfo, SkuInfo },
  name: "common-product-table",
  props: {
    key: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    gallery: {
      type: Array as () => ProductItemImage[],
      default: () => {
        return [...productItemData.gallery];
      },
    },
    name: {
      type: String,
      default: () => {
        return "";
      },
    },
    name_en: {
      type: String,
      default: () => {
        return "";
      },
    },
    brand_id: {
      type: String,
      default: () => {
        return "";
      },
    },
    color: {
      type: String,
      default: () => {
        return "";
      },
    },
    size: {
      type: String || null,
      default: () => {
        return null;
      },
    },
    capacity: {
      type: String || null,
      default: () => {
        return null;
      },
    },
    rangeSize: {
      type: String || null,
      default: () => {
        return null;
      },
    },
    source_link: {
      type: String,
      default: () => {
        return "";
      },
    },
    product_identifier_id: {
      type: String,
      default: () => {
        return "0";
      },
    },
    brand_article_no: {
      type: String,
      default: () => {
        return "";
      },
    },
    merchant_article_no: {
      type: String,
      default: () => {
        return "";
      },
    },
    productCol: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    skuCol: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    ean: {
      type: String,
      default: () => {
        return "";
      },
    },
    eanShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    country: {
      type: String,
      default: () => {
        return "";
      },
    },
    countryShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(props, context) {
    const { t } = useI18n();

    const rangeSizeModal = (key: number) => {
      context.emit("range-size-modal", key);
    };

    const getName = computed(() => {
      return JwtService.getLang() === "zh_CN" ? props.name : props.name_en;
    });

    return {
      t,
      props,
      rangeSizeModal,
      getName,
    };
  },
});
