
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["range-size-modal"],
  name: "common-sku-info",
  props: {
    key: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    color: {
      type: String,
      default: () => {
        return "";
      },
    },
    normalColor: {
      type: String,
      default: () => {
        return "";
      },
    },
    size: {
      type: String || null,
      default: () => {
        return null;
      },
    },
    capacity: {
      type: String || null,
      default: () => {
        return null;
      },
    },
    rangeSize: {
      type: String || null,
      default: () => {
        return null;
      },
    },
    product_identifier_id: {
      type: String,
      default: () => {
        return "0";
      },
    },
    brand_article_no: {
      type: String,
      default: () => {
        return "";
      },
    },
    merchant_article_no: {
      type: String,
      default: () => {
        return "";
      },
    },
    ean: {
      type: String,
      default: () => {
        return "";
      },
    },
    eanShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    country: {
      type: String,
      default: () => {
        return "";
      },
    },
    countryShow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    maxWidth: {
      type: String,
      default: () => {
        return "200px";
      },
    },
  },
  setup(props, context) {
    const rangeSizeModal = (key) => {
      context.emit("range-size-modal", key);
    };

    return {
      rangeSizeModal,
    };
  },
});
