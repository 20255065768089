/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-24 18:10:33
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-08 15:27:13
 */

export enum CurrencyType {
  Percent = 1,
  Amount = 2,
}
