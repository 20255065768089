
import { computed, defineComponent, getCurrentInstance, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  productItemData,
  ProductItemImage,
} from "@/core/directive/interface/order";
import { previewOrderImages } from "@/core/directive/function/order";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "common-product-info",
  props: {
    gallery: {
      type: Array as () => ProductItemImage[],
      default: () => {
        return [...productItemData.gallery];
      },
    },
    name: {
      type: String,
      default: () => {
        return "";
      },
    },
    name_en: {
      type: String,
      default: () => {
        return "";
      },
    },
    brand_id: {
      type: String,
      default: () => {
        return "";
      },
    },
    source_link: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  setup(props) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();

    const options = ref({
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    const getName = computed(() => {
      return JwtService.getLang() === "zh_CN" ? props.name : props.name_en;
    });

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      currentInstance.proxy.emitter.emit("preview-images", {
        sources: previewOrderImages(previewImages),
        sourcesIndex: imageIndex,
        sourcesSlide: imageIndex + 1,
        toggler: options.value.toggler,
      });
    };

    return {
      t,
      props,
      options,
      getName,
      previewImages,
    };
  },
});
